import React from "react";

function SocialButtons() {

    return (
        <div className="social_buttons_wrap">
            <div className="social_position">
                <a href="#">
                    <div className="social_img_wrap">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10 1C5.0297 1 1 5.05403 1 10.0543C1 14.5729 4.29073 18.3181 8.59406 19V12.6722H6.30783V10.0543H8.59406V8.05946C8.59406 5.78841 9.93789 4.53607 11.9928 4.53607C12.9775 4.53607 14.009 4.71265 14.009 4.71265V6.93933H12.8713C11.7552 6.93933 11.4059 7.63839 11.4059 8.35466V10.0525H13.9001L13.5014 12.6704H11.4059V18.9982C15.7093 18.32 19 14.5738 19 10.0543C19 5.05403 14.9703 1 10 1Z"/>
                        </svg>
                    </div>
                </a>

                <a href="#">
                    <div className="social_img_wrap">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.997 5.372C7.443 5.372 5.374 7.442 5.374 9.995C5.374 12.549 7.443 14.619 9.997 14.619C12.549 14.619 14.62 12.549 14.62 9.995C14.62 7.442 12.549 5.372 9.997 5.372ZM9.997 12.999C8.338 12.999 6.993 11.654 6.993 9.996C6.993 8.337 8.338 6.993 9.997 6.993C11.656 6.993 12.999 8.337 12.999 9.996C12.999 11.654 11.656 12.999 9.997 12.999Z"/>
                            <path
                                d="M14.804 6.28C15.3994 6.28 15.882 5.79736 15.882 5.202C15.882 4.60664 15.3994 4.124 14.804 4.124C14.2086 4.124 13.726 4.60664 13.726 5.202C13.726 5.79736 14.2086 6.28 14.804 6.28Z"/>
                            <path
                                d="M18.531 4.106C18.062 2.897 17.107 1.941 15.898 1.474C15.199 1.211 14.46 1.07 13.712 1.054C12.749 1.012 12.444 1 10.002 1C7.56 1 7.247 1 6.292 1.054C5.546 1.069 4.807 1.21 4.108 1.474C2.898 1.941 1.943 2.897 1.475 4.106C1.212 4.806 1.071 5.544 1.056 6.292C1.013 7.254 1 7.559 1 10.002C1 12.444 1 12.755 1.056 13.712C1.071 14.46 1.212 15.198 1.475 15.899C1.944 17.107 2.899 18.063 4.109 18.531C4.805 18.803 5.544 18.957 6.294 18.981C7.257 19.023 7.562 19.036 10.004 19.036C12.446 19.036 12.759 19.036 13.714 18.981C14.461 18.966 15.2 18.824 15.9 18.562C17.109 18.093 18.064 17.138 18.533 15.929C18.796 15.229 18.937 14.491 18.952 13.743C18.995 12.781 19.008 12.476 19.008 10.033C19.008 7.59 19.008 7.28 18.952 6.323C18.939 5.565 18.799 4.814 18.531 4.106ZM17.313 13.638C17.306 14.214 17.202 14.785 17.002 15.326C16.697 16.113 16.076 16.735 15.29 17.037C14.755 17.236 14.191 17.34 13.62 17.348C12.67 17.392 12.402 17.403 9.966 17.403C7.528 17.403 7.279 17.403 6.311 17.348C5.742 17.341 5.176 17.236 4.642 17.037C3.853 16.736 3.228 16.114 2.923 15.326C2.727 14.792 2.621 14.227 2.612 13.657C2.569 12.707 2.559 12.439 2.559 10.003C2.559 7.566 2.559 7.317 2.612 6.348C2.619 5.772 2.723 5.202 2.923 4.661C3.228 3.872 3.853 3.251 4.642 2.949C5.176 2.751 5.742 2.646 6.311 2.638C7.262 2.595 7.529 2.583 9.966 2.583C12.403 2.583 12.653 2.583 13.62 2.638C14.191 2.645 14.755 2.75 15.29 2.949C16.076 3.252 16.697 3.874 17.002 4.661C17.198 5.195 17.304 5.76 17.313 6.33C17.356 7.281 17.367 7.548 17.367 9.985C17.367 12.421 17.367 12.683 17.324 13.639H17.313V13.638Z"/>
                        </svg>
                    </div>
                </a>

                <a href="#">
                    <div className="social_img_wrap">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.0078 18H15.0078V11.25C15.0078 10.1914 13.8199 9.3058 12.7613 9.3058C11.7027 9.3058 11.0078 10.1914 11.0078 11.25V18H7.00782V6H11.0078V8C11.6701 6.92857 13.3642 6.23727 14.5328 6.23727C17.0043 6.23727 19.0078 8.2786 19.0078 10.75V18ZM5.00781 18H1.00781V6H5.00781V18ZM3.00782 0C4.11239 0 5.00782 0.89543 5.00782 2C5.00782 3.10457 4.11239 4 3.00782 4C1.90325 4 1.00782 3.10457 1.00782 2C1.00782 0.89543 1.90325 0 3.00782 0Z"/>
                        </svg>
                    </div>
                </a>

                <a href="#">
                    <div className="social_img_wrap">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20 3.894C19.2642 4.21528 18.4733 4.4324 17.6434 4.53002C18.4904 4.03013 19.1411 3.23857 19.4475 2.29532C18.6546 2.75825 17.7765 3.09438 16.8418 3.27548C16.0934 2.49047 15.0271 2 13.8469 2C11.5808 2 9.74349 3.80844 9.74349 6.03911C9.74349 6.35567 9.77982 6.66396 9.84981 6.95962C6.43961 6.79115 3.41611 5.18309 1.39238 2.7393C1.03916 3.33586 0.836813 4.0297 0.836813 4.76997C0.836813 6.17133 1.56118 7.40765 2.66221 8.13205C1.98965 8.11096 1.35688 7.92932 0.803713 7.62679C0.803321 7.64366 0.803321 7.66052 0.803321 7.67755C0.803321 9.63464 2.21772 11.2671 4.09478 11.6382C3.75049 11.7305 3.38799 11.78 3.01377 11.78C2.74938 11.78 2.49233 11.7545 2.24175 11.7075C2.76392 13.3121 4.27925 14.48 6.07481 14.5125C4.67051 15.596 2.90127 16.2417 0.978812 16.2417C0.647611 16.2417 0.320999 16.2225 0 16.1851C1.81587 17.3312 3.97271 18 6.28989 18C13.8372 18 17.9645 11.8452 17.9645 6.50746C17.9645 6.33237 17.9605 6.15816 17.9525 5.98489C18.7543 5.4154 19.45 4.70402 20 3.894Z"/>
                        </svg>

                    </div>
                </a>

                <a href="#">
                    <div className="social_img_wrap">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.2532 8.309H9.92619V11.76H14.7182C14.2722 13.953 12.4052 15.213 9.92619 15.213C7.00319 15.213 4.64719 12.857 4.64719 9.933C4.64719 7.01 7.00319 4.654 9.92619 4.654C11.1852 4.654 12.3232 5.101 13.2162 5.832L15.8162 3.233C14.2322 1.852 12.2012 1 9.92619 1C4.97219 1 0.992188 4.979 0.992188 9.934C0.992188 14.889 4.97119 18.868 9.92619 18.868C14.3932 18.868 18.4552 15.619 18.4552 9.934C18.4552 9.406 18.3742 8.837 18.2532 8.309Z"/>
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default SocialButtons;