const invoiceOptions = {
  /*  "publicKey": "",
    "order": {
      "id": "",
      "amount": "",
      "currency": "USD",
      "items": [
        {
          "id": "1",
          "name": "imbacoin",
          "price": ""
        }
        
      ],
      "description": "testing payop"
    },
    "signature": "",  
    "payer": {
      "email": "",
     
    },
    "paymentMethod": "381",
    "language": "en",
    "resultUrl": "",
    "failPath": ""*/
    "amount": "",
    "currency" : 'USD', 
    "email" : '',
    "domain": ''

  }

  const signatureOptions = { "amount": "", "currency" : 'USD' }

  export  { invoiceOptions, signatureOptions };